import React, { useContext, useEffect } from "react";
import { MainContext } from "../App";
import functions from "../utilities/functions";
import InfoIcon from "../icons/InfoIcon";

function Products() {
	const {
		category,
		handleAddProduct,
		setProductSheet,
		cart,
		editQty,
		animations,
		setAnimations,
	} = useContext(MainContext);

	useEffect(() => {
		for (const productId of animations) {
			const el = document.querySelector("[data-id='" + productId + "']");
			if (el) {
				el.classList.add("success");
				setAnimations(animations.slice(1));
				window.setTimeout(() => el.classList.remove("success"), 300);
			}
		}
	}, [animations]);

	const getQty = (productId) => {
		let qty = 0;
		for (const p of cart.products) {
			if (p.id == productId) qty += parseInt(p.qty);
		}
		return qty;
	};

	const handleEditProduct = (productId, type) => {
		const index = cart.products.findLastIndex((p) => p.id == productId);
		if (index > -1) editQty(index, type);
	};

	return (
		<div className="scroll">
			{category &&
				category.products.map((product, i) => (
					<div key={i} className="list-button" data-id={product.id}>
						<button
							className="btn btn-secondary btn-square"
							onClick={() => setProductSheet(product)}
						>
							<InfoIcon />
						</button>
						<button className="product-name" onClick={() => handleAddProduct(product)}>
							{product.name}
							<div className="small">
								{functions.formatter.format(product.price)}
								{product.stock !== false && (
									<span> - Rimanenti: {product.stock} pezzi</span>
								)}
							</div>
						</button>
						<div>
							<div className="plus_minus_container">
								<button
									className={
										"btn btn-square" +
										(getQty(product.id) > 0 ? " btn-primary" : "")
									}
									onClick={() => handleEditProduct(product.id, "minus")}
								>
									&#8722;
								</button>
								<div>{getQty(product.id)}</div>
								<button
									className="btn btn-primary btn-square"
									onClick={() => handleAddProduct(product)}
								>
									&#43;
								</button>
							</div>
						</div>
					</div>
				))}
		</div>
	);
}

export default Products;
